<template>
  <div class="offer-item__header">
    <template v-if="isArchived">
      <div
        class="offer-item__header__line slim-header-line"
        style="display: flex; justify-content: space-between"
      >
        <div style="display: flex">
          <IconEmployee
            width="24"
            height="24"
          />
          <h2 class="offer-item__header__vacancy-name">
            {{ shift? shift.vacancy.title : '' }}
          </h2>
        </div>

        <div>
          <span class="offer-item__header__vacancy-name">
            {{ `Неоплачеваемый перерыв: ${shift.offer.break ? shift.offer.break.duration : 0} минут` }}
          </span>
          <span class="offer-item__header__vacancy-name">
            <IconQuestionMark
              width="18"
              height="18"
              style="margin-right: 10px"
              :active="shift.offer.hasQuestionList"
            />
            <IconColdMark
              width="18"
              height="18"
              style="margin-right: 10px"
              :active="shift.offer.weatherCondition === 'cold'"
            />
            <IconBus
              width="18"
              height="18"
              style="margin-right: 10px"
              :active="shift.offer.hasTransportation"
            />
            <IconFreeEat
              width="18"
              height="18"
              style="margin-right: 10px"
              :active="shift.offer.freeFood"
            />
          </span>
        </div>
      </div>
      <div
        v-if="!isRejectedInvitation"
        class="offer-item__header__line slim-header-line"
      >
        <span style="display: inline-block; width: 150px">Фактические: </span>
        <IconCalendar
          width="18"
          height="18"
        />
        <span class="offer-item__header__vacancy-name">
          {{ shift.startDate }}
        </span>

        <IconTime
          width="18"
          height="18"
        />

        <span class="offer-item__header__vacancy-name">
          {{ `${shift.startTime} - ${shift.endTime}` }}
        </span>
        <div class="offer-item__header__vacancy-name">
          <div class="offer-item__header__hour">
            {{ shift.duration }}
          </div>
        </div>
        <AppTextDateTime class="offer-item__header__date-start">
          <template #date>
            <span
              class="offer-item__header__money-info"
              style="padding-right: 5px"
            >ФОТ:</span>
          </template>
          <template #time>
            {{ shift.amount }}
          </template>
        </AppTextDateTime>
      </div>
      <div class="offer-item__header__line slim-header-line">
        <span style="display: inline-block; width: 150px">Плановые: </span>
        <IconCalendar
          width="18"
          height="18"
        />

        <span class="offer-item__header__vacancy-name">
          {{ shift.offer.startDate }}
        </span>

        <IconTime
          width="18"
          height="18"
        />

        <span class="offer-item__header__vacancy-name">
          {{ `${shift.offer.startTime } - ${shift.offer.endTime}` }}
        </span>
        <div class="offer-item__header__vacancy-name">
          <div class="offer-item__header__hour">
            {{ shift.offer.duration }}
          </div>
        </div>
        <AppTextDateTime class="offer-item__header__date-start">
          <template #date>
            <span
              class="offer-item__header__money-info"
              style="padding-right: 5px"
            >ФОТ:</span>
          </template>
          <template #time>
            {{ shift.offer.expense }}
          </template>
        </AppTextDateTime>
      </div>
      <div
        class="offer-item__header__line slim-header-line"
      >
        <span
          v-if="hasPretension"
          style="display: inline-block; width: 104px"
        >Претензия: </span>
        <IconFileDoc
          v-if="hasPretension"

          style="cursor: pointer"
          :width="14"
          :height="20"
          @click.native="showPretension"
        />
        <span
          v-if="hasBill"
          style="display: inline-block; width: 41px;margin-left: 128px"
        >Акт: </span>
        <IconFileDoc
          v-if="hasBill"

          style="cursor: pointer"
          :width="14"
          :height="20"
          @click.native="showBill"
        />
        <!--        <AppTextDateTime class="offer-item__header__date-start">-->
        <!--          <template #date>-->
        <!--            <span-->
        <!--              class="offer-item__header__money-info"-->
        <!--              style="padding-right: 5px"-->
        <!--            />-->
        <!--          </template>-->
        <!--          <template #time>-->
        <!--            {{ shift.pretension.amount }}-->
        <!--          </template>-->
        <!--        </AppTextDateTime>-->
      </div>
      <!--      <div class="offer-item__header__line slim-header-line">-->
      <!--        <div class="offer-item__header__temperature">-->
      <!--          Должностные обязанности-->
      <!--          <p-->
      <!--            class="offer-item__header__money-info"-->
      <!--            style="-->
      <!--            margin: 0;-->
      <!--            display: inline-block;-->
      <!--            padding: 0;-->
      <!--            text-decoration: underline"-->
      <!--          >-->
      <!--            Развернуть-->
      <!--          </p>-->
      <!--        </div>-->
      <!--      </div>-->
      <template>
        <div class="offer-item__header__line slim-header-line">
          <OfferItemChatUserCard
            :response-laborer="shift"
            :selected-user="null"
            :noBorder="true"
            :showExperience="false"
            :isOffer="false"
            :showUnreadMessageNumber="false"
          />
          <AppButton
            v-if="!isRejectedInvitation"
            style="margin-left: 10px"
            class="wb-orange-button slim-button"
            @click.native="onStopShift"
          >
            {{ !hasActiveIncident ? 'Создать инцидент' : 'к инциденту' }}
          </AppButton>
          <AppButton
            v-if="!isRejectedInvitation && !shift.rate"
            class="slim-button"
            style="margin-left: auto"
            @click.native="rate"
          >
            Оценить
          </AppButton>
          <div class="offer-item__header__status">
            <span
              v-if="isRejectedInvitation"
              class="button-text-simple"
              style="color: red"
            >
              {{ 'Соискатель отказался' }}
            </span>
            <span v-else>
              {{ getStatus(shift) }}
            </span>
          </div>
        </div>
      </template>
    </template>
    <template v-else>
      <div class="offer-item__header__line slim-header-line">
        <AppTextDateTime
          v-if="shift.status === statusList.STATUS_ACCEPTED || shift.status === statusList.STATUS_CREATED ||
            shift.status === statusList.STATUS_ACTIVE"
          class="offer-item__header__date-start"
        >
          <template #date>
            {{ getStatusLabel }}
          </template>
          <template #time>
            <span :style="{'color': stopScheduled ? '#FF0000' : ''}">
              {{ currentTimer }}
            </span>
          </template>
        </AppTextDateTime>
        <div class="setting-title-wrapper">
          <p
            v-for="info in getTitleList"
            :key="info.label"
            :class="{'setting-title__error': info.type === 'error' }"
            class="setting-title"
          >
            {{ info.label }}
          </p>
        </div>
        <WbdDropdown
          style="width: 250px; margin-left: auto"
          :label="'Управление сменой'"
          :menuItems="getSettingActionList"
          @onDropdownSelected="onDropdownSelected"
        />
      </div>
      <div
        v-if="!mini"
        class="offer-item__header__line slim-header-line"
      >
        <IconEmployee
          width="18"
          height="18"
        />

        <AppTextDateTime class="offer-item__header__date-start">
          <template #date>
            <h2 class="offer-item__header__vacancy-name">
              {{ shift.vacancy.title }}
            </h2>
          </template>
        </AppTextDateTime>
        <AppTextDateTime class="offer-item__header__date-start">
          <template #date>
            <span
              class="offer-item__header__money-info"
              style="padding-right: 5px"
            >ФОТ:</span>
          </template>
          <template #time>
            {{ shift.amount }}
          </template>
        </AppTextDateTime>

        <!--        <AppButton-->
        <!--          v-if="!stopScheduled && shift.status === 'active' && !hasAbsence"-->
        <!--          class="wb-orange-button slim-button"-->
        <!--          style="margin-left: auto"-->
        <!--          @click.native="laborerAbsent"-->
        <!--        >-->
        <!--          Соискателя нет на месте!-->
        <!--        </AppButton>-->
      </div>
      <div
        v-if="!mini"
        class="offer-item__header__line slim-header-line"
      >
        <IconCalendar
          width="18"
          height="18"
        />

        <span class="offer-item__header__vacancy-name">
          {{ isPlanedShift ? shift.offer.startDate : shift.startDate }}
        </span>

        <IconTime
          width="18"
          height="18"
        />

        <span class="offer-item__header__vacancy-name">
          {{
            isPlanedShift ? `${getOffer.startTime } - ${getOffer.endTime}` : `${shift.startTime} - ${shift.endTime}`
          }}
        </span>

        <span class="offer-item__header__vacancy-name">
          {{ `Неоплачеваемый перерыв: ${shift.offer.break ? shift.offer.break.duration : 0} минут` }}
        </span>

        <span class="offer-item__header__vacancy-name">
          <IconQuestionMark
            width="18"
            height="18"
            style="margin-right: 10px"
            :active="shift.offer.hasQuestionList"
          />
          <IconColdMark
            width="18"
            height="18"
            style="margin-right: 10px"
            :active="shift.offer.weatherCondition === 'cold'"
          />
          <IconBus
            width="18"
            height="18"
            style="margin-right: 10px"
            :active="shift.offer.hasTransportation"
          />
          <IconFreeEat
            width="18"
            height="18"
            style="margin-right: 10px"
            :active="shift.offer.freeFood"
          />
        </span>

        <div class="offer-item__header__vacancy-name">
          <div class="offer-item__header__hour">
            {{ isPlanedShift ? shift.offer.duration : shift.duration }}
          </div>
        </div>
      </div>
      <template v-if="!mini">
        <div
          v-for="info in additionalInfo"
          :key="info.value"
          class="offer-item__header__line slim-header-line"
        >
          <IconComplete
            :fill="'rgba(0, 0, 0, 0.71)'"
            :fill-circle="'1'"
            width="18"
            height="18"
          />
          <div class="offer-item__header__additional-info">
            {{ info.label }}
          </div>
        </div>

        <div
          style="border: none"
          class="offer-item__header__line slim-header-line"
        >
          <!--          <AppButton-->
          <!--            v-if="shift.status !== statusList.STATUS_CREATED && !stopScheduled && footerAction"-->
          <!--            class="wb-red-button slim-button offer-item__header__line__button"-->
          <!--            @click.native="onStopShift"-->
          <!--          >-->
          <!--            {{ footerAction }}-->
          <!--          </AppButton>-->
          <!--          <AppButton-->
          <!--            v-if="shift.status !== statusList.STATUS_CREATED && !stopScheduled && !isConfirmed"-->
          <!--            class="wb-red-button slim-button offer-item__header__line__button"-->
          <!--            @click.native="cancelShift"-->
          <!--          >-->
          <!--            {{ 'Отменить смену' }}-->
          <!--          </AppButton>-->

          <div class="offer-item__header__status">
            {{ getStatus(shift) }}
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import IconEmployee from '@/components/common/icons/IconEmployee'
import IconTime from '@/components/common/icons/IconTime'
import IconBus from '@/components/common/icons/IconBus'
import IconFreeEat from '@/components/common/icons/IconFreeEat'
import IconQuestionMark from '@/components/common/icons/IconQuestionMark'
import IconColdMark from '@/components/common/icons/IconColdMark'
import IconCalendar from '@/components/common/icons/IconCalendar'
import IconComplete from '@/components/common/icons/IconComplete'
import AppTextDateTime from '@/components/common/AppTextDateTime'
import AppButton from '@/components/common/simple/AppButton'
import { DATE_FORMAT } from '@/utils/date_utils'
import ShiftMixin from '@/mixins/shift/index.vue'
import OfferItemChatUserCard from '@/components/offer/OfferItemChatUserCard'
import IconFileDoc from '@/components/common/icons/IconFileDoc'
import { routeList } from '@/router/office'
import userUtil from '@/utils/user-util'
import WbdDropdown from '@/components/navbar/NavigationItemDropDown'

const START_SETTING_ID = 'start'
const SCHEDULE_SETTING_ID = 'schedule-start'
const CANCEL_SETTING_ID = 'cancel'
const REJECT_LABORER_SETTING_ID = 'reject-laborer'
const DELAY_SETTING_ID = 'delay'
const LABORER_ABSENCE_SETTING_ID = 'laborer-absence'
const EXTEND_SETTING_ID = 'extend'
const STOP_SETTING_ID = 'stop'
const CANCEL_LABORER_ABSENCE = 'cancel-laborer-absence'
const CANCEL_REJECT_REQUEST = 'cancel-reject-request'
const CONFIRM_START = 'confirm-start'
const CREATE_AND_CONFIRM_START = 'create-and-confirm-start'

export default {
  name: 'OfferItemHeader',
  components: {
    WbdDropdown,
    OfferItemChatUserCard,
    IconTime,
    IconQuestionMark,
    IconColdMark,
    IconFileDoc,
    IconFreeEat,
    IconBus,
    AppButton,
    AppTextDateTime,
    IconComplete, IconEmployee, IconCalendar
  },
  mixins: [ShiftMixin],
  props: {
    mini: {
      type: Boolean,
    },
    shift: {
      type: Object,
    },
    currentTimer: {
      type: String,
    },
    isArchived: {
      type: Boolean,
    },
    showCreateButton: {
      type: Boolean,
    },
    hasAbsence: {
      type: Boolean,
    },
    hasRejectRequest: {
      type: Boolean,
    }
  },
  data () {
    return {
      showFull: false,
      settingItemList: [
        { 'label': 'Подтвердить начало смены', 'id': START_SETTING_ID },
        { 'label': 'Начать смену планово', 'id': SCHEDULE_SETTING_ID },
        { 'label': 'Не устраивает Исполнитель', 'id': REJECT_LABORER_SETTING_ID },
        { 'label': 'Отменить смену', 'id': CANCEL_SETTING_ID },
      ]
    }
  },
  computed: {
    isNotLateByPlan () {
      return this.shift.status === this.statusList.STATUS_ACCEPTED &&
        this.$moment(this.shift.startAt).isSameOrAfter(this.$moment())
    },
    isRejectedInvitation () {
      return this.shift.level === 'invitation'
    },
    getTitleList () {
      const list = []
      if (this.delayed) {
        list.push({
          label: 'Начало смены перенесено',
          type: 'info',
        })
      }

      if (this.extended) {
        list.push({
          label: this.extensionLabel,
          type: 'info',
        })
      }

      if (this.shift.status === this.statusList.STATUS_ACTIVE && !this.isConfirmed) {
        list.push({
          label: 'Требует подтверждения!',
          type: 'error',
        })
      }
      if (this.hasAbsence) {
        list.push({
          label: 'Работника нет на месте',
          type: 'error',
        })
      }
      return list
    },
    getSettingActionList () {
      let currentList = []
      if (this.hasAbsence) {
        currentList = [
          { 'label': 'Продолжить смену', 'id': CANCEL_LABORER_ABSENCE },
          { 'label': 'Завершить смену', 'id': STOP_SETTING_ID },
        ]
      } else if (this.stopScheduled) {
        currentList = [
          { 'label': 'Не могу найти работника', 'id': LABORER_ABSENCE_SETTING_ID },
          // { 'label': 'Завершить смену', 'id': STOP_SETTING_ID },
        ]
      } else if (this.hasRejectRequest) {
        currentList = [
          { 'label': 'Работник передумал', 'id': CANCEL_REJECT_REQUEST },
          { 'label': 'Не могу найти работника', 'id': LABORER_ABSENCE_SETTING_ID },
          { 'label': 'Завершить смену', 'id': STOP_SETTING_ID },
        ]
      } else {
        switch (this.shift.status) {
        case this.statusList.STATUS_ACCEPTED:
          currentList = [
            { 'label': 'Отменить смену', 'id': CANCEL_SETTING_ID },
          ]
          // чтобы не отображался этот пункт пока не понятно на всегда или нет
          if (!this.delayed && this.isNotLateByPlan) {
            currentList.push({ 'label': 'Перенести начало смены', 'id': DELAY_SETTING_ID })
          }
          // показать кнопку создания смены
          if (this.showCreateButton) {
            currentList.push({ 'label': 'Подтвердить и начать смену', 'id': CREATE_AND_CONFIRM_START },
            )
          }
          break
        case this.statusList.STATUS_CREATED:
          currentList = [
            { 'label': 'Начать смену', 'id': START_SETTING_ID },
            { 'label': 'Не устраивает Исполнитель', 'id': REJECT_LABORER_SETTING_ID },
            // { 'label': 'Не могу найти работника', 'id': LABORER_ABSENCE_SETTING_ID },
            { 'label': 'Отменить смену', 'id': CANCEL_SETTING_ID },
          ]
          break
        case this.statusList.STATUS_ACTIVE:
          if (!this.isConfirmed) {
            currentList = [
              { 'label': 'Подтвердить начало смены', 'id': CONFIRM_START },
              { 'label': 'Меня не устраивает исполнитель', 'id': REJECT_LABORER_SETTING_ID },
              { 'label': 'Не могу найти работника', 'id': LABORER_ABSENCE_SETTING_ID },
              { 'label': 'Отменить смену', 'id': CANCEL_SETTING_ID },
            ]
          } else {
            currentList = [
              { 'label': 'Не могу найти работника', 'id': LABORER_ABSENCE_SETTING_ID },
              { 'label': 'Завершить смену', 'id': STOP_SETTING_ID },
            ]
            if (!this.extended) {
              currentList.unshift({ 'label': 'Продлить смену', 'id': EXTEND_SETTING_ID },)
            }
          }
          break
        }
      }


      return currentList
    },
    currentOffice () {
      if (userUtil.isRoot(this.currentUser)) {
        return { id: userUtil.getRootCurrentOffice() }
      }
      return this.$store.state.office.userOffice
    },
    getStatusLabel () {
      return this.getTimerStatusLabel(this.shift)
    },
    hasIncident () {
      return this.shift.incident && this.shift.incident.id
    },
    stopScheduled () {
      return this.shift.status === this.statusList.STATUS_ACTIVE &&
        (this.hasIncident || this.hasAbsence || this.hasRejectRequest)
    },
    hasBill () {
      return this.shift && this.shift.bill && this.shift.bill.path
    },
    hasPretension () {
      return this.shift && this.shift.pretension && this.shift.pretension.file && this.shift.pretension.file.id
    },
    getOffer () {
      return this.shift.offer
    },
    extensionButtonActive () {
      return this.extended
    },
    extended () {
      return !!this.shift.extensionRequest && !!this.shift.extensionRequest.id
    },
    currentUser () {
      return this.$store.state.users.info
    },
    delayed () {
      return this.shift.status === this.statusList.STATUS_ACCEPTED && !!this.shift.offer.delayed
    },
    extensionLabel () {
      if (!this.extended) {
        return 'Продлить смену'
      }
      switch (this.shift.extensionRequest.status) {
      case 'created':
        return 'Ожидание продления'
      case 'cancelled':
        return 'В продлении отказано'
      case 'done':
      case 'confirmed':
        return 'Продление согласовано'
      default:
        return ''
      }
    },

    hasActiveIncident () {
      return this.hasIncident && this.shift.incident.status === 'active'
    },
    footerAction () {
      switch (this.shift.status) {
      case this.statusList.STATUS_CREATED:
        return 'Не устраивает Исполнитель'
        // return 'Отменить смену'
      case this.statusList.STATUS_ACTIVE:
        return this.shift.startConfirmed ? 'Завершить смену' : 'Не устраивает Исполнитель'
      default:
        return null
      }
    },
    isNew () {
      return this.shift.status === this.statusList.STATUS_CREATED
    },
    isConfirmed () {
      return this.shift.startConfirmed
    },
    isPlanedShift () {
      return this.shift && this.shift.status === 'accepted'
    },
    additionalInfo () {
      const { offer } = this
      const list = [
        { name: 'Корпоративный транспорт', value: 'hasTransportation' },
        { name: 'Бесплатный обед', value: 'freeFood' },
      ]
      if (!offer) return []
      return list.filter((item) => offer[item.value])
    }
  },
  methods: {
    getMonth (month) {
      return DATE_FORMAT.long[month]
    },
    onDelay () {
      this.$emit('openModal', this.modalList.DELAY_MODAL)
      // this.start()
    },
    onExtend () {
      this.$emit('openModal', this.modalList.EXTEND_SHIFT_MODAL)
    },
    start () {
      this.$emit('openModal', this.modalList.CAN_START_SHIFT_MODAL)
    },
    showBill () {
      if (this.shift && this.shift.bill && this.shift.bill.path) {
        const win = window.open(this.shift.bill.path, '_blank')
        win.focus()
      }
    },
    showPretension () {
      if (this.hasPretension) {
        const win = window.open(this.shift.pretension.file.path, '_blank')
        win.focus()
      }
    },
    onConfirmStart () {
      this.$emit('openModal', this.modalList.SHIFT_CONFIRM_START_MODAL)
    },
    laborerAbsent () {
      this.$emit('onLaborerMissing')
    },
    rate () {
      this.$emit('openModal', this.modalList.SHIFT_RATE_MODAL)
    },
    laborerMissingCancel () {
      this.$emit('onLaborerMissingCancel')
    },
    cancelShift () {
      this.$emit('onCancel')
    },
    onDropdownSelected (item) {
      switch (item.id) {
      case CONFIRM_START:
        this.$emit('openModal', this.modalList.SHIFT_CONFIRM_START_MODAL)
        break
      case CREATE_AND_CONFIRM_START:
        this.$emit('openModal', this.modalList.SHIFT_CREATE_MODAL)
        break
      case CANCEL_REJECT_REQUEST:
        this.$emit('onCancelRejectRequest')
        break
      case EXTEND_SETTING_ID:
        this.onExtend()
        break
      case CANCEL_LABORER_ABSENCE:
        this.$emit('onLaborerMissingCancel')
        break
      case LABORER_ABSENCE_SETTING_ID:
        this.$emit('onLaborerMissing')
        break
      case START_SETTING_ID:
        this.$emit('openModal', this.modalList.CAN_START_SHIFT_MODAL)
        // this.$emit('openModal', this.modalList.CAN_START_SHIFT_MODAL)
        break
      case CANCEL_SETTING_ID:
        this.$emit('openModal', this.modalList.SHIFT_CONFIRM_ACTION_MODAL)
        break
      case REJECT_LABORER_SETTING_ID:
      case STOP_SETTING_ID:
        this.onStopShift()
        break
      case DELAY_SETTING_ID:
        this.$emit('openModal', this.modalList.DELAY_MODAL)
        break
      case SCHEDULE_SETTING_ID:
        this.$emit('openModal', this.modalList.SHIFT_SCHEDULE_START_MODAL)
        // /
        break
      default:
        // TODO добавить
        break
      }
    },
    onStopShift () {
      if (this.hasActiveIncident) {
        this.$router.push({
          name: routeList.OFFICE_INCIDENT_ITEM_ROUTE_NAME,
          params: { id: this.currentOffice.id, incidentId: this.shift.incident.id },
        })
      } else {
        switch (this.shift.status) {
        case this.statusList.STATUS_CREATED:
          this.$emit('openModal', this.modalList.REJECT_LABORER_MODAL)
          break
        case this.statusList.STATUS_ACTIVE:
          if (!this.isArchived && !this.shift.startConfirmed) {
            this.$emit('openModal', this.modalList.REJECT_LABORER_MODAL)
          } else {
            this.$emit('openModal', this.modalList.STOP_SHIFT_MODAL)
          }
          break
        default:
          if (this.isArchived) {
            this.$emit('openModal', this.modalList.STOP_SHIFT_MODAL)
          }
          break
        }
      }
    }
  }
}
</script>
<style lang="sass" scoped>
  .setting-title-wrapper
    min-width: 200px
    margin-left: auto
    display: flex
    flex-direction: column
    justify-content: center
    align-items: flex-start

  .setting-title
    font-weight: 600
    color: #1862D1
    font-size: 14px
    line-height: 16px
    opacity: 0.4
    &__error
      color: red
  .slim-button
    padding: 4px 10px !important

  .slim-header-line
    padding: 3px 0 !important

  .offer-item__header
    font-family: $mainFont
    display: flex
    flex-direction: column
    align-items: center
    background-color: white
    padding: 5px 30px

    &__line
      position: relative
      display: flex
      align-items: center
      width: 100%
      border-bottom: $default_border_bottom
      padding: 12px 0
    &__vacancy-name
      margin-left: 10px
      margin-right: 15px

    &__dropper
      @include fontStyle(600, 13px)
      cursor: pointer
      text-decoration-line: underline
      color: rgba(0, 0, 0, 0.5)

    &__money-info, &__hour-info
      @include fontStyle(500, 12px)
      margin-left: auto
      margin-right: 10px
      width: 180px
      text-align: right
      color: rgba(0, 0, 0, 0.2)

    &__money, &__hour
      @include fontStyle(800, 28px)
      text-align: right

    &__hour
      font-size: 20px
      background: #F0F0F1
      border-radius: 6px
      padding: 8px
    &__temperature
      @include fontStyle(500, 14px)
      &__value
        font-weight: 600
    &__additional-info
      @include fontStyle(600, 14px)
      margin-left: 10px
    &__status
      @include fontStyle(600, 16px)
      margin-left: auto
      margin-right: 21px
      color: #8BD118
    &__view-count
      @include fontStyle(500, 16px)
      color: rgba(0, 0, 0, 0.2)
      margin-left: 5px

</style>
