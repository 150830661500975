<template>
  <label class="textarea-wrapper">
    <textarea
      style="background: transparent"
      :class="{
        'textarea-field_medium': size === 'medium'
      }"
      :placeholder="placeholder"
      :value="value"
      :disabled="disabled"
      class="textarea-field"
      @input="inputValue"
      @change="onChangeEvent(value)"
    />
    <div
      v-if="!hideClose"
      class="textarea-clear"
      @click="$emit('clear', 1)"
    >
      ×
    </div>
  </label>
</template>

<script>
export default {
  name: 'AppTextArea',
  props: {
    value: {
      type: [String, Number]
    },
    disabled: {
      type: Boolean
    },
    placeholder: {
      type: String,
      required: true
    },
    hideClose: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    },
    onChange: {
      type: Function,
      required: false
    },
    fieldId: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
    }
  },
  methods: {
    inputValue ($event) {
      this.$emit('input', $event.target.value)
      this.$emit('update', this.fieldId)
    },
    onChangeEvent ($event) {
      if (this.onChange) {
        this.onChange($event)
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.textarea
  &-wrapper
    width: 100%
    display: flex
    align-items: center

  &-clear
    @include fontStyle(500, 28px)
    opacity: .3
    margin-left: 10px
    cursor: pointer

  &-field
    resize: none
    width: 100%
    min-height: 60px
    padding: 15px 20px 10px 20px
    border: 1.5px solid rgba(155, 155, 155, 0.30)
    border-radius: 25px
    transition: .3s border cubic-bezier(0.23, 1, 0.32, 1)

    &_medium
      height: 170px
</style>
