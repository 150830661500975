<template>
  <svg :width="width || '29'" :height="height || '39'" viewBox="0 0 29 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M20 0.800003H0.200012V38.2H28.8V9.6L20 0.800003ZM20 9.6H4.60001V11.8H20V9.6ZM4.60001 16.2H24.4V18.4H4.60001V16.2ZM24.4 22.8H4.60001V25H24.4V22.8ZM24.4 29.4H17.8V31.6H24.4V29.4Z" fill="black"/>
  </svg>
</template>

<script>
    import icon_config from "@/mixins/icon_config";

    export default {
        name: "IconFileDoc",
        mixins: [icon_config],
    }
</script>

<style scoped>

</style>
