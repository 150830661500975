import { render, staticRenderFns } from "./IconFreeEat.vue?vue&type=template&id=e797a304&scoped=true"
import script from "./IconFreeEat.vue?vue&type=script&lang=js"
export * from "./IconFreeEat.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e797a304",
  null
  
)

export default component.exports