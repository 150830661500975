<template>
  <svg :width="width" :height="height" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.3 10.8C6.3 11.7941 5.49411 12.6 4.5 12.6C3.50589 12.6 2.7 11.7941 2.7 10.8C2.7 9.80589 3.50589 9 4.5 9C5.49411 9 6.3 9.80589 6.3 10.8Z" fill="#7A7A7A"/>
    <path d="M16.2 10.8C16.2 11.7941 15.3941 12.6 14.4 12.6C13.4059 12.6 12.6 11.7941 12.6 10.8C12.6 9.80589 13.4059 9 14.4 9C15.3941 9 16.2 9.80589 16.2 10.8Z" fill="#7A7A7A"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.49213 0.393921C5.6597 0.147502 5.93837 0 6.23637 0H17.1C17.5971 0 18 0.402943 18 0.9V9.9C18 10.3971 17.5971 10.8 17.1 10.8C17.1 9.30883 15.8912 8.1 14.4 8.1C12.9088 8.1 11.7 9.30883 11.7 10.8H7.2C7.2 9.30883 5.99117 8.1 4.5 8.1C3.00883 8.1 1.8 9.30883 1.8 10.8H0.9C0.402944 10.8 0 10.3971 0 9.9V6.04868C0 5.6613 0.247887 5.31737 0.615395 5.19487L2.7 4.5L5.49213 0.393921ZM9 1.35H6.75L4.59001 4.5H9V1.35ZM9.9 1.35H12.6V4.5H9.9V1.35ZM16.2 1.35H13.5V4.5H16.2V1.35Z" :fill="active ? activeColor : fill || '#7A7A7A'"
    />
  </svg>
</template>

<script>
    import icon_config from "@/mixins/icon_config";

    export default {
        name: "IconBus",
        mixins: [icon_config],
    }
</script>

<style scoped>

</style>
