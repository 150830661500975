<template>
  <AppMainContainer
    v-if="currentShift"
    center-class="offer-item"
    style="padding-bottom: 0"
    :show-modal="!!currentModal"
    @closeModal="closeModal"
  >
    <template #subheader>
      <v-expansion-panels
        v-model="panelEnabled"
        :multiple="false"
      >
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span v-if="panelEnabled === 0" />
            <ShitViewHeader
              v-else
              id="shift-header-container"
              mini
              :show-create-button="showCreateButton"
              :shift="currentShift"
              :hasAbsence="hasAbsence"
              :hasRejectRequest="hasRejectRequest"
              :isArchived="isArchived"
              :currentTimer="currentTimer"
              @openModal="openModal"
              @onCancel="onCancel"
              @onCancelRejectRequest="cancelRejectRequest"
              @onConfirmStart="onConfirmStart"
              @onLaborerMissing="laborerMissing"
              @onLaborerMissingCancel="laborerMissingCancel"
            />
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <PageHeader
              :showOrganizationBalance="false"
              :isAdmin="iAmOrganizationRoot || iAmOrganizationAdmin"
            />
            <div style="margin-top: 16px;" />
            <ShitViewHeader
              id="shift-header-container"
              :show-create-button="showCreateButton"
              :shift="currentShift"
              :hasAbsence="hasAbsence"
              :hasRejectRequest="hasRejectRequest"
              :isArchived="isArchived"
              :currentTimer="currentTimer"
              @openModal="openModal"
              @onCancel="onCancel"
              @onCancelRejectRequest="cancelRejectRequest"
              @onConfirmStart="onConfirmStart"
              @onLaborerMissing="laborerMissing"
              @onLaborerMissingCancel="laborerMissingCancel"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>

    <template #center>
      <div
        v-if="!isArchived"
        :style="chatStyle"
        class="offer-item mt-4"
      >
        <div
          style="width: 45%;"
          class="offer-item__left"
        >
          <div
            class="offer-item__left__list-user"
          >
            <OfferItemChatUserCard
              v-for="respondent in [currentShift]"
              :key="respondent.id"
              :style="panelEnabled ? undefined :{minHeight: '540px'} "
              style="border: none"
              :response-laborer="currentShift"
              :selected-user="selectedUser"
              :isOffer="false"
              @click="selectLaborer(currentShift)"
            />
          </div>
        </div>

        <div
          class="offer-item__right"
          style="width: 55%; background: white"
        >
          <OfferItemChat
            :selectedStatus="{}"
            :selected-user="currentShift"
            :respondents="[getChatOffer.laborer]"
            :offer="getChatOffer"
            :isOffer="false"
          />
        </div>
      </div>
    </template>
    <template #modal>
      <AppModalMiddle
        v-if="currentModal === modalList.EXTEND_SHIFT_MODAL"
        style="padding: 16px; width: 450px"
      >
        <h1 class="modal__header">
          {{ 'Продление смены' }}
        </h1>
        <div v-if="extensionDisabled">
          <p
            class="modal__description"
            style="margin-bottom: 20px; color: rgba(0,0,0,0.6)"
          >
            Вы не можете продлить текущую смену, так как она достигла максимальной длительности смены
          </p>
          <v-card-actions>
            <v-spacer />
            <v-btn
              rounded
              class="success"
              @click="currentModal = ''"
            >
              Ок
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </div>
        <div v-else-if="!getFullExtensionInMinutes">
          <p
            class="modal__description"
            style="margin-bottom: 20px; color: rgba(0,0,0,0.6)"
          >
            Вы не можете продлить текущую смену, так как до следующей смены Соискателя осталось
            {{ prependZero(getNextShiftStartRemainHours) }}
            {{ _pluriaizeHours(getNextShiftStartRemainHours) }}
            {{ getNextShiftStartRemainMinutes }} {{ _pluriaizeMinutes(getNextShiftStartRemainMinutes) }}
          </p>
          <v-card-actions>
            <v-spacer />
            <v-btn
              rounded
              class="success"
              @click="currentModal = ''"
            >
              Ок
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </div>
        <div v-else>
          <p
            class="modal__description"
            style="margin-bottom: 20px; color: rgba(0,0,0,0.6)"
          >
            {{ getExtensionMaxHourMessage }}
          </p>

          <h2
            class="modal__description"
            style="margin-bottom: 20px; color: rgba(0,0,0,0.6)"
          >
            Смена можно продлить еще на
            {{ `${prependZero(getExtensionDurationHoursValue)}:${prependZero(getExtensionDurationMinutesValue)}` }}
          </h2>
          <div style="display: flex; justify-content: center; align-items: center">
            <AppInput
              v-model="hours"
              style="max-width: 70px; margin-right: 12px"
              placeholder=" "
              :onKeyUp="keyUp"
              :onChange="onHoursChange"
              :name="'extendHours'"
              :disabled="!getMaxAllowedExtensionDurationInHours"
            />
            <span>Часа</span>
            <AppInput
              v-model="minutes"
              style="max-width: 70px; margin: 12px"
              placeholder=" "
              :onKeyUp="keyUp"
              :onChange="onMinutesChange"
              :name="'extendMinutes'"
              :disabled="!relayExtendable"
              @update:value="onMinutesUpdate"
            />
            <span>минут</span>
          </div>
          <v-alert
            v-if="hasNextShift && extensionConflictWithNextShift"
            outlined
            :color="!relayExtendable || getFullExtensionInMinutes === 0 ? 'error' : 'warning'"
            class="py-2 px-2"
            dense
            :icon="undefined"
            style="border-color: transparent !important;"
          >
            Вы можете продлить текущую смену Соискателя на {{ getExtensionDurationHoursValue }}
            {{ _pluriaizeHours(getExtensionDurationHoursValue) }} {{ getExtensionDurationMinutesValue }}
            {{ _pluriaizeMinutes(getExtensionDurationMinutesValue) }},
            так как его следующая смена начнется через {{ prependZero(getNextShiftStartRemainHours) }}
            {{ _pluriaizeHours(getNextShiftStartRemainHours) }}
            {{ getNextShiftStartRemainMinutes }} {{ _pluriaizeMinutes(getNextShiftStartRemainMinutes) }}
            После завершения текущей смены.
          </v-alert>
          <p
            class="modal__description"
            style="margin-bottom: 20px; color: rgba(0,0,0,0.6)"
          >
            Стоимость продления смены:
          </p>
          <div style="font-weight: bolder; font-size: 38px; text-align: center">
            {{ getExtendPay }} ₽
          </div>
          <p
            class="modal__description"
            style="margin-bottom: 20px; color: rgba(0,0,0,0.6)"
          >
            Для вступления предложения в силу
            требуется дождаться согласия работника.
          </p>
          <div
            v-if="refillAmountRequired"
            style="color: #ED462F"
            class="modal-text mt-3 text-center"
          >
            {{ $t('offer.creation.not_enough_funds') }}:
            <span style="font-size: 1.2em; font-weight: 900">
              {{ getRequiredAmount }} &#8381;
            </span>
          </div>
          <div
            v-if="isRequested"
            class="modal__button"
            style="text-align: center"
          >
            <AppLoader
              style="margin: 10px"
            />
          </div>
          <div
            v-else
            class="modal__button"
            style="text-align: right;margin-top: 8px"
          >
            <AppButton
              class="button-text-simple"
              @click.native="currentModal = ''"
            >
              <span style="color: rgba(0,0,0,0.3);">Отмена</span>
            </AppButton>
            <AppButton
              v-if="refillAmountRequired"
              class=""
              style="margin-bottom: 16px"
              @click.native="goToRefillAmount"
            >
              Пополните ваш баланс
            </AppButton>
            <AppButton
              v-else
              class=""
              style="margin-bottom: 16px"
              :disabled="!extendButtonActive"
              @click.native="proceedExtend"
            >
              Продлить смену
            </AppButton>
          </div>
        </div>
      </AppModalMiddle>
      <AppModalSmall
        v-if="currentModal === modalList.DELAY_MODAL"
        style="padding: 70px 70px"
      >
        <h1 class="modal__header">
          Перенос начала смены:
        </h1>

        <p class="modal__description">
          Начало смены будет перенесено на <strong style="font-weight: bolder; color: black">30 минут</strong>
        </p>
        <p
          class="modal__description"
          style="max-width: 80%; margin: 20px auto 0;font-size: 0.8em"
        >
          Определите, будет ли перенесено
          время окончания смены на 30 минут:
        </p>

        <div
          v-if="isRequested"
          class="modal__button"
          style="text-align: center"
        >
          <AppLoader
            style="margin: 10px"
          />
        </div>
        <div
          v-else
          class="modal__button"
          style="text-align: center"
        >
          <AppButton
            class=""
            style="margin-bottom: 16px"
            @click.native="proceedDelay"
          >
            Закончить смену по плану
          </AppButton>
          <AppButton
            class="button-stroke"
            style="margin-bottom: 16px"
            @click.native="proceedDelay(true)"
          >
            Закончить смену на 30 минут позже
          </AppButton>
          <AppButton
            class="button-text-simple"
            @click.native="currentModal = ''"
          >
            <span style="color: rgba(0,0,0,0.3);">Отмена</span>
          </AppButton>
        </div>
      </AppModalSmall>
      <AppModalSmall
        v-if="currentModal === modalList.DELAY_MODAL_CONFIRM_MODAL"
        style="padding: 70px 70px"
      >
        <h1 class="modal__header">
          Начало смены перенесено
        </h1>

        <p class="modal__description">
          Плановое начало смены
        </p>

        <p
          v-if="getDelayedStartDate"
          class="modal__description"
        >
          {{ getDelayedStartDate.delayedDate }}
          <strong style="font-weight: bolder; color: black"> {{ getDelayedStartDate.delayedTime }}</strong>`
        </p>
        <div
          class="modal__button"
          style="text-align: center"
        >
          <AppButton
            class=""
            style="margin-bottom: 16px"
            @click.native="currentModal = ''"
          >
            OK
          </AppButton>
        </div>
      </AppModalSmall>
      <AppModalSmall
        v-if="currentModal === modalList.START_CONFIRM_MODAL"
        style="padding: 16px; width: 340px"
      >
        <h1 class="modal__header">
          {{ confirmationModal.title }}
        </h1>
        <div
          class="modal__button"
          style="text-align: center"
        >
          <AppButton
            class=""
            style="margin-bottom: 16px"
            @click.native="currentModal = ''"
          >
            OK
          </AppButton>
        </div>
      </AppModalSmall>
      <AppModalSmall
        v-if="currentModal === modalList.CAN_START_SHIFT_MODAL"
        style="padding: 16px; width: 380px"
      >
        <h1 class="modal__header">
          Соискатель прибыл на место!
        </h1>

        <p
          class="modal__header"
          style="margin-top: 20px"
        >
          Всё в порядке?
        </p>
        <p class="modal__header">
          Вы готовы начать смену?
        </p>

        <div
          v-if="isRequested"
          class="modal__button"
          style="text-align: center"
        >
          <AppLoader
            style="margin: 10px"
          />
        </div>
        <div
          v-else
          class="modal__button"
          style="text-align: center"
        >
          <AppButton
            class=""
            style="margin-bottom: 16px; width: 230px"
            @click.native="startShift(false)"
          >
            Начать смену сейчас
          </AppButton>
          <!--          <span-->
          <!--                      style="display: inline-block; margin-left: 10px; -->
          <!--          min-width: 150px; text-align: left;visibility: hidden"-->
          <!--          > {{ startModalStartDate }}-->
          <!--          </span>-->
          <!--          <AppButton-->
          <!--            class=""-->
          <!--            style="margin-bottom: 16px; width: 230px"-->
          <!--            @click.native="currentModal = ''"-->
          <!--          >-->
          <!--            Начать смену планово-->
          <!--          </AppButton>-->
          <!--          <span-->
          <!--            style="display: inline-block; margin-left: 10px; ; min-width: 150px; text-align: left"-->
          <!--          >{{ shiftFullFormattedStartDate }}-->
          <!--          </span>-->
          <AppButton
            style="display: block; width: 120px; margin: 0 auto; text-align: center"
            class="button-text-simple "
            @click.native="closeOnStart"
          >
            <span style="font-weight: bold">Закрыть</span>
          </AppButton>
        </div>
      </AppModalSmall>
      <AppModalSmall
        v-if="currentModal === modalList.SHIFT_CONFIRM_START_MODAL"
        style="padding: 16px; width: 385px"
      >
        <h1 class="modal__header">
          Подтверждение начала смены
        </h1>

        <!--        <p-->
        <!--          class="button-text-simple"-->
        <!--          style="margin-top: 20px;font-weight: bold; text-align: center"-->
        <!--        >-->
        <!--          Смена была планова запущена системой.-->
        <!--        </p>-->
        <div
          v-if="isRequested"
          class="modal__button"
          style="text-align: center"
        >
          <AppLoader
            style="margin: 10px"
          />
        </div>
        <div
          v-else
          class="modal__button"
          style="text-align: center"
        >
          <AppButton
            class=""
            style="margin-bottom: 16px; width: 230px"
            @click.native="onConfirmStart"
          >
            Начать смену
          </AppButton>
          <AppButton
            style="display: block; margin: auto"
            class="button-text-simple "
            @click.native="closeOnStart"
          >
            <span style="font-weight: bold">Отмена</span>
          </AppButton>
        </div>
      </AppModalSmall>
      <AppModalSmall
        v-if="currentModal === modalList.SHIFT_CREATE_MODAL"
        style="padding: 16px; width: 385px"
      >
        <h1 class="modal__header">
          Подтверждение начала смены
        </h1>

        <div>
          <p
            class="modal__description"
            style="margin-bottom: 20px; color: rgba(0,0,0,0.6)"
          >
            Этим действием вы подтверждаете, что исполнитель прибыл на объект и готов выполнять работу
          </p>
        </div>

        <div
          v-if="isRequested"
          class="modal__button"
          style="text-align: center"
        >
          <AppLoader
            style="margin: 10px"
          />
        </div>
        <div
          v-else
          style="display: flex; flex-direction: row; justify-content: space-between; align-items: center"
        >
          <AppButton
            class="button-text-simple "
            @click.native="currentModal = ''"
          >
            <span style="font-weight: bold">Отмена</span>
          </AppButton>
          <AppButton
            class=" "
            @click.native="startShift(true)"
          >
            <span style="font-weight: bold">Подтверждаю</span>
          </AppButton>
        </div>
      </AppModalSmall>
      <AppModalSmall
        v-if="currentModal === modalList.REJECT_LABORER_MODAL"
        style="padding: 70px 70px"
      >
        <h1 class="modal__header">
          Не устраивает исполнитель
        </h1>

        <div style="margin-top: 16px">
          <AppSelect
            style="width: 100%"
            placeholder="Выберите причину"
            :value.sync="laborerRejectData.incidentType"
            :visual-value="laborerRejectData.incidentType ? laborerRejectData.incidentType.title : ''"
            :values="laborerRejectReasonList"
            class="offer-modal__select"
          >
            <template #default="{value}">
              <span style="display: inline-block;text-align: left">{{ value.title }}</span>
            </template>
          </AppSelect>
        </div>
        <p
          class="modal__description"
          style="margin-bottom: 20px; color: black"
        >
          Ваш комментарий
        </p>
        <AppTextArea
          v-model="laborerRejectData.description"
          :placeholder="'Опишите ситуацию, чтобы мы могли с ней разобраться'"
          :hide-close="true"
          size="medium"
        />
        <div
          v-if="isRequested"
          class="modal__button"
          style="text-align: center"
        >
          <AppLoader
            style="margin: 10px"
          />
        </div>
        <div
          v-else
          class="modal__button"
          style="text-align: center"
        >
          <AppButton
            :disabled="!laborerRejectData.incidentType || !laborerRejectData.incidentType.id"
            class=""
            style="display: block"
            @click.native="rejectLaborer"
          >
            Отменить смену
          </AppButton>
          <AppButton
            class="button-text-simple "
            @click.native="currentModal = ''"
          >
            <span style="font-weight: bold">Отмена</span>
          </AppButton>
        </div>
      </AppModalSmall>
      <AppModalSmall
        v-if="currentModal === modalList.STOP_SHIFT_MODAL"
        style="padding: 16px; width: 450px"
      >
        <h1 class="modal__header">
          Досрочное завершение смены
        </h1>
        <div v-if="requiredCompensationConfirmation">
          <p
            class="modal__description"
            style="margin-bottom: 20px; color: black; text-align: left"
          >
            Выбирая данную причину. С вашего баланса дополнительно
            будет списана компенсация Исполнителю в размере 50%
            оплаты от оставшегося времени до конца смены, в соответствии
            с условиями Оферты.
          </p>
          <div
            class="modal__button"
            style="display: flex; flex-direction: row; justify-content: space-between"
          >
            <AppButton
              class="button-text-simple "
              @click.native="compensationConfirmed = !compensationConfirmed"
            >
              <span style="font-weight: bold">Подтвердить</span>
            </AppButton>
            <AppButton
              class=""
              style="display: inline-block"
              @click.native="onStopShiftCancel"
            >
              Я передумал(а)
            </AppButton>
          </div>
        </div>
        <div v-else>
          <div
            style="margin-top: 16px"
          >
            <AppSelect
              style="width: 100%"
              placeholder="Выберите причину"
              :value.sync="earlyStopData.incidentType"
              :visual-value="earlyStopData.incidentType ? earlyStopData.incidentType.title : ''"
              :values="incidentTypeList"
              :disabled="isArchived && hasIncident"
              class="offer-modal__select"
            >
              font-weight: bold
              <template #default="{value}">
                <span style="display: inline-block;text-align: left">{{ value.title }}</span>
              </template>
            </AppSelect>
          </div>

          <div
            v-if="!isArchived &&
              earlyStopData.incidentType &&
              earlyStopData.incidentType.alias === earlyWorkFinishedAlias"
            style="display: flex; justify-content: start; align-items: center;"
          >
            <span>Завершение смены через:</span>
            <AppInput
              v-model="toStopMinutes"
              style="max-width: 70px; margin: 16px"
              placeholder=" "
              :onKeyUp="keyUp"
              :onChange="onToStopMinutes"
              :name="'toStopMinutes'"
            />
            <span>минут</span>
          </div>
          <p
            class="modal__description"
            style="margin-bottom: 20px; color: black; text-align: left"
          >
            Опишите ситуацию при необходимости
          </p>
          <AppTextArea
            v-model="earlyStopData.description"
            :placeholder="'Опишите ситуацию, чтобы мы могли с ней разобраться'"
            :hide-close="true"
            size="medium"
          />
          <div
            v-if="isRequested"
            class="modal__button"
            style="text-align: center"
          >
            <AppLoader
              style="margin: 10px"
            />
          </div>
          <div
            v-else
            class="modal__button"
            style="text-align: center"
          >
            <AppButton
              class="button-text-simple "
              @click.native="currentModal = ''"
            >
              <span style="font-weight: bold">Отмена</span>
            </AppButton>
            <AppButton
              :disabled="!canStopShift"
              class=""
              style="display: inline-block"
              @click.native="onStopShiftOrCreateIncident"
            >
              {{ isArchived ? 'Создать' : 'Завершить' }}
            </AppButton>
          </div>
        </div>
      </AppModalSmall>
      <AppModalSmall
        v-if="currentModal === modalList.REJECT_SHIFT_REQUEST_MODAL"
        style="padding: 16px"
      >
        <h1
          style="margin-bottom: 10px"
          class="modal__header"
        >
          Соискатель просит завершить смену досрочно
          по причине <span v-if="currentShift.rejectRequest && currentShift.rejectRequest.id">
            {{ `«${currentShift.rejectRequest.reason}»` }}
          </span>
        </h1>
        <div
          class="reject-request-line line-divider__top"
        >
          <div style="display: flex">
            <IconEmployee
              width="24"
              height="24"
            />&nbsp;
            <h2 class="offer-item__header__vacancy-name">
              {{ currentShift.vacancy.title }}
            </h2>
          </div>
        </div>
        <div
          class="reject-request-line line-divider__top"
        >
          <IconCalendar
            width="18"
            height="18"
          />&nbsp;
          <span class="offer-item__header__vacancy-name">
            {{ currentShift.startDate }}
          </span>&nbsp;
          <IconTime
            width="18"
            height="18"
          />&nbsp;
          <span class="offer-item__header__vacancy-name">
            {{ `${currentShift.startTime} - ${currentShift.endTime}` }}
          </span>
        </div>
        <div
          class="reject-request-line line-divider__top"
        >
          <OfferItemChatUserCard
            style="padding: 0 !important;"
            :response-laborer="currentShift"
            :selected-user="null"
            :noBorder="true"
            :showExperience="false"
            :isOffer="false"
            :showUnreadMessageNumber="false"
          />
        </div>
        <div
          v-if="hasRejectRequest && currentShift.rejectRequest.description"
          class="reject-request-line__bordered chat-box line-divider__top"
        >
          <p
            class="modal__description"
            style="align-self: flex-start; text-align: left; margin-top: 0"
          >
            <span class="chat-author">{{ currentShift.laborer.fullName }}</span>&nbsp;
            <span class="chat-time">{{ $moment(currentShift.rejectRequest.createdAt).format('HH:mm') }}</span>
          </p>
          <p
            class="modal__description"
            style="color: rgba(0,0,0,0.6); align-self: flex-start; text-align: left"
          >
            {{ currentShift.rejectRequest.description }}
          </p>
        </div>
        <div
          class="reject-request-line"
          style="align-items: center; justify-content: center; margin-top: 8px"
        >
          <p
            class="modal__description reject-description"
          >
            Завершение смены автоматически согласуется через:
          </p>
        </div>
        <div
          class="reject-request-line"
          style="align-items: center; justify-content: center"
        >
          <p
            class="reject-timer-label"
          >
            {{ currentTimer }}
          </p>
        </div>
        <div
          class="reject-request-line"
          style="align-items: center; justify-content: center"
        >
          <p
            class="modal__description reject-description"
          >
            Примите решение:
          </p>
        </div>

        <div
          v-if="isRequested"
          class="modal__button"
          style="text-align: center; margin-top: 0"
        >
          <AppLoader
            style="margin: 10px"
          />
        </div>
        <div
          v-else
          class="modal__button"
          style="text-align: center; margin-top: 0"
        >
          <AppButton
            class="button-text-simple "
            @click.native="cancelShift(false)"
          >
            <span style="font-weight: bold">Завершить сейчас</span>
          </AppButton>
          <AppButton
            class=""
            style="display: inline-block"
            @click.native="cancelRejectRequest()"
          >
            {{ 'Работник передумал' }}
          </AppButton>
        </div>
      </AppModalSmall>
      <AppModalSmall
        v-if="currentModal === modalList.SHIFT_CANCELLED_MODAL"
        style="padding: 70px 70px"
      >
        <h1 class="modal__header">
          {{ isArchived ? 'Инцидент успешно создан!' : 'Смена отменена' }}
        </h1>

        <p
          class="modal__description"
          style="margin-bottom: 20px; color: rgba(0,0,0,0.6)"
        >
          Наша служба устранения конфликтных ситуаций
          немедленно разберётся в инциденте.
        </p>
        <div
          class="modal__button"
          style="text-align: center"
        >
          <AppButton
            class=""
            style="display: block"
            @click.native="currentModal = ''"
          >
            ОК
          </AppButton>
        </div>
      </AppModalSmall>
      <AppModalSmall
        v-if="currentModal === modalList.SHIFT_EXTEND_CONFIRM_MODAL"
        style="padding: 16px; max-width: 380px"
      >
        <h1 class="modal__header">
          {{ 'Смена будет продлена' }}
        </h1>

        <p
          class="modal__description"
          style="margin-bottom: 20px; color: rgba(0,0,0,0.6)"
        >
          Мы ожидаем ответа работника.
        </p>

        <p
          class="modal__description"
          style="margin-bottom: 20px; color: rgba(0,0,0,0.6)"
        >
          Для вступления предложения в силу
          требуется дождаться его согласия.
        </p>
        <div
          class="modal__button"
          style="text-align: center"
        >
          <AppButton
            class=""
            style="display: block"
            @click.native="currentModal = ''"
          >
            ОК
          </AppButton>
        </div>
      </AppModalSmall>
      <AppModalSmall
        v-if="currentModal === modalList.SHIFT_CONFIRM_ACTION_MODAL"
        style="padding: 16px; max-width: 380px"
      >
        <h1 class="modal__header">
          {{ 'Отмена запланированной смены' }}
        </h1>

        <p
          class="modal__description"
          style="margin-bottom: 20px; color: rgba(0,0,0,0.6)"
        >
          {{
            currentShift.status === statusList.STATUS_ACCEPTED || !currentShift.startConfirmed ?
              (getCancelFutureShiftMessage) :
              ('Если вы отмените данную смену с баланса будет списан 1 оффер и компенсация затрат Исполнителю в ' +
                'размере 25% от стоимости данной смены, в соответствии с условиями Оферты'
              )
          }}
        </p>
        <div
          v-if="isRequested"
          class="modal__button"
          style="text-align: center; margin-top: 0"
        >
          <AppLoader
            style="margin: 10px"
          />
        </div>
        <div
          v-else
          class="modal__button"
          style="text-align: center"
        >
          <AppButton
            class="button-text-simple "
            @click.native="onCancel"
          >
            <span style="font-weight: bold">Отменить смену</span>
          </AppButton>
          <AppButton
            class="button-text-simple "
            @click.native="currentModal = ''"
          >
            <span style="font-weight: bold">Я передумал</span>
          </AppButton>
        </div>
      </AppModalSmall>
      <AppModalSmall
        v-if="currentModal === modalList.SHIFT_SCHEDULE_START_MODAL"
        style="padding: 16px; max-width: 380px"
      >
        <h1 class="modal__header">
          {{ 'Подтверждение планового начала смены' }}
        </h1>

        <p
          class="modal__description"
          style="margin-bottom: 20px; color: rgba(0,0,0,0.6)"
        >
          Автоматическое начала смены {{ `${currentShift.startDate} ${currentShift.startTime}` }}
          Бла Бла бла ...
        </p>
        <div
          v-if="isRequested"
          class="modal__button"
          style="text-align: center; margin-top: 0"
        >
          <AppLoader
            style="margin: 10px"
          />
        </div>
        <div
          v-else
          class="modal__button"
          style="text-align: center"
        >
          <AppButton
            class="button-text-simple "
            @click.native="currentModal = ''"
          >
            <span style="font-weight: bold">Я передумал</span>
          </AppButton>
          <AppButton
            class=""
            @click.native="onConfirmStart"
          >
            Подтверждаю
          </AppButton>
        </div>
      </AppModalSmall>
      <AppModalSmall
        v-if="currentModal === modalList.SHIFT_RATE_MODAL"
        style="padding: 20px 20px"
      >
        <h1 class="modal__header">
          Смена завершена!
        </h1>
        <p class="modal__description">
          Смена успешно завершена, оцените работника:
        </p>
        <div style="margin-top: 20px; margin-left: 16px; text-align: center">
          <OfferItemChatUserCard
            v-if="currentShift.laborer"
            class="shift-grid__user-info"
            :laborer="currentShift.laborer"
          />
        </div>
        <div style="display: flex;justify-content: center; align-items: center; margin-top: 16px">
          <div class="star">
            <AppStarWrapper
              width="36"
              height="32"
              :value="rate.value"
              edit
              :count="5"
              is-rating
              @hover="hover"
              @click="onRateValueChange"
            />
          </div>
        </div>
        <div>
          <p class="modal__description">
            {{ rate.value >= 4 ? 'За что можно похвалить работника' : 'Что разочаровало в работнике?' }}
          </p>
          <div
            class="rate-reason-container"
          >
            <span
              v-for="rateReason in getRatingIconList"
              :key="rateReason.id"
              class="rate-reason-container-item"
              @click="onRateReasonClick(rateReason)"
            >
              <component
                :is="rateReason.alias"
                :active="selectedRateReason.includes(rateReason.id)"
              />
            </span>
          </div>
        </div>
        <div
          v-if="isOrganizationManager && !currentShift.favoriteLaborer && parseInt(rate.value) >=4"

          style="margin-top: 16px"
          class="d-flex flex-row justify-content-center align-content-center"
        >
          <AppCombobox
            v-model="rate.isFavorite"
            style="width: 10%"
          />
          <span
            style="display: inline-block; margin-top: 2px; font-weight: bold; font-size: 1em"
            class="modal__description"
          >
            Сделать моей командой
          </span>
        </div>
        <div
          v-if="isRequested"
          class="modal__button"
          style="text-align: center"
        >
          <AppLoader
            style="margin: 10px"
          />
        </div>
        <div
          v-else
          class="modal__button"
          style="text-align: center"
        >
          <AppButton
            :disabled="parseInt(rate.value) < 1"
            class=""
            style="display: block"
            @click.native="proceedRate"
          >
            Оценить работника
          </AppButton>
          <AppButton
            style="margin-top: 10px"
            class="button-text-simple"
            @click.native="currentModal = ''"
          >
            <strong>Нет, спасибо!</strong>
          </AppButton>
        </div>
      </AppModalSmall>
      <AppModalSmall
        v-if="currentModal === modalList.SHIFT_RATE_CONFIRMATION_MODAL"
        style="padding: 16px; max-width: 380px"
      >
        <h1 class="modal__header">
          {{ 'Спасибо за оценку!' }}
        </h1>
        <div
          class="modal__button"
          style="text-align: center"
        >
          <AppButton
            class=""
            style="display: block"
            @click.native="currentModal = ''"
          >
            Продолжить
          </AppButton>
        </div>
      </AppModalSmall>
    </template>
  </AppMainContainer>
</template>

<script>
import AppMainContainer from '@/components/common/layout/AppMainContainer'
import ShitViewHeader from '@/components/shift/ShitViewHeader.vue'
import AppStarWrapper from '@/components/common/AppStarWrapper'
import OfferItemChatUserCard from '@/components/offer/OfferItemChatUserCard'
import OfferItemChat from '@/components/offer/OfferItemChat'
import AppModalSmall from '@/components/common/modal/AppModalSmall'
import AppButton from '@/components/common/simple/AppButton'
import AppSelect from '@/components/common/simple/AppSelect'
import AppTextArea from '@/components/common/simple/AppTextArea'
import ShiftMixin from '@/mixins/shift/index.vue'
import { localFormattedDate } from '@/utils/date_utils'
import AppInput from '@/components/common/simple/AppInput'
import AppModalMiddle from '../components/common/modal/AppModalMiddle'
import AppLoader from '@/components/AppLoader'
import routeList from '@/router/labels'
import IconEmployee from '@/components/common/icons/IconEmployee'
import IconTime from '@/components/common/icons/IconTime'
import IconCalendar from '@/components/common/icons/IconCalendar'
import AppTextDateTime from '@/components/common/AppTextDateTime'
import taxMixin from '@/mixins/taxMixin.vue'
import AppCombobox from '@/components/common/simple/AppCombobox'
import userUtil from '@/utils/user-util'
import { pluriaizeHours, pluriaizeMinutes } from '@/utils/plural-util'
import PageHeader from '@/components/subheaders/PageHeader.vue'

export default {
  name: 'PageOfferItem',
  components: {
    PageHeader,
    IconTime,
    AppCombobox,
    IconCalendar,
    AppTextDateTime,
    AppModalMiddle,
    IconEmployee,
    AppSelect,
    AppInput,
    AppLoader,
    AppStarWrapper,
    AppTextArea,
    AppButton,
    AppModalSmall,
    OfferItemChat,
    OfferItemChatUserCard,
    ShitViewHeader, AppMainContainer
  },
  mixins: [ShiftMixin, taxMixin],
  props: {
    offerId: {
      type: String
    }
  },
  data () {
    return {
      currentTimer: '',
      panelEnabled: null,
      chatStyle: null,
      extensionConflictWithNextShift: false,
      showCreateButton: false,
      selectedUser: null,
      selectedRateReason: [],
      isRequested: false,
      confirmationModal: {
        title: ''
      },
      currentTimerInterval: null,
      hours: '01',
      minutes: '00',
      toStopMinutes: '05',
      laborerRejectData: {
        incidentType: null,
        description: ''
      },
      earlyStopData: {
        incidentType: null,
        description: ''
      },
      rate: {
        value: 5,
        isFavorite: false,
        reason: {
          id: null
        },
      },
      offer: null,
      comment: null,
      respondents: [],
      currentModal: '',
      compensationConfirmed: false,
    }
  },
  computed: {
    getExtensionMaxHourMessage () {
      return `Общее Количество часов смены в 1 календарный день
            не должно превышать ${this.getRelayMaxDuration} ${pluriaizeHours(this.getRelayMaxDuration)}.`
    },
    currentUser () {
      return this.$store.state.users.info
    },
    isOrganizationManager () {
      if (!this.currentUser || !this.currentUser.id) {
        return false
      }
      return userUtil.isManager(this.currentUser)
    },
    getCancelFutureShiftMessage () {
      if (this.currentShift.laborerIsLate) {
        return 'Если вы отмените смену когда соискатель опаздывает, тогда оффер и деньги вернутся на ваш баланс'
      }
      return 'В случае отмены запланированной смены, с вашего баланса будет списан оффер,' +
        ' денежные средства за работу исполнителя вернутся в полном объеме. Вы подтверждаете отмену смены?'
    },
    requiredCompensationConfirmation () {
      return this.isEarlyWorkFinishedReason && !this.compensationConfirmed && !this.currentShift.isRemote
    },
    isEarlyWorkFinishedReason () {
      if (!this.earlyStopData.incidentType || !this.earlyStopData.incidentType.alias) {
        return false
      }
      return this.earlyStopData.incidentType.alias === this.earlyWorkFinishedAlias
    },
    canStopShift () {
      const incident = this.earlyStopData.incidentType

      if (!incident) {
        return false
      }

      if (incident.alias === this.otherAlias) {
        const description = this.earlyStopData.description
        return incident && incident.id && description && description.trim().length > 1
      }
      return incident && incident.id
    },
    getChatOffer () {
      return { ...this.currentShift.offer, laborer: this.currentShift.laborer }
    },
    getToStopMaxMinutes () {
      const splitedMinutes = this.currentTimer.split(':')
      let maxMinutes = 0

      if (splitedMinutes.length > 1) {
        maxMinutes = parseInt(splitedMinutes[0]) * 60 + parseInt(splitedMinutes[1])
      }

      return maxMinutes > 10 ? maxMinutes : 0
    },
    getToStopMinMinutes () {
      return 5
    },
    getDecimal (number) {
      number = parseFloat(this.getMaxAllowedExtensionDurationInHours)
      const str = number + ''
      const splited = str.split('.')
      if (splited.length > 0) {
        return {
          hours: this.prependZero(this.getMaxAllowedExtensionDurationInHours),
          minutes: this.getExtensionDurationMinutesValue
        }
      }

      return 0
    },
    canIncreaseHour () {
      // {parseInt(getMaxAllowedExtensionDurationInHours)}:${prependZero(getExtensionDurationMinutesValue)}`
      return parseInt(this.hours) <= this.getExtensionDurationHoursValue
    },
    canIncreaseMinute () {
      let maxMinutes = 59
      const currentHours = parseInt(this.hours, 10)
      if (!this.getExtensionDurationHoursValue || (currentHours >= this.getExtensionDurationHoursValue)) {
        maxMinutes = this.getExtensionDurationMinutesValue
      }
      return parseInt(this.minutes) <= maxMinutes
    },
    extendButtonActive () {
      return (parseInt(this.getExtensionDurationMinutesValue) > 0 || this.getExtensionDurationHoursValue > 0) &&
        this.relayExtendable && this.canIncreaseHour && this.canIncreaseMinute && (
        parseInt(this.hours, 10) > 0 || parseInt(this.minutes, 10) > 0
      )
    },
    extensionDisabled () {
      return this.getExtensionDurationMinutesValue === 0 && this.getMaxAllowedExtensionDurationInHours === 0
    },
    getCurrentRelayFullDurationInHours () {
      if (!this.currentShift) {
        return 0
      }
      const { offer } = this.currentShift
      const { break: { duration } } = offer
      let breakDurationHours = 0
      if (duration > 0) {
        breakDurationHours = duration / 60
      }

      const result = this.currentShift.hours + breakDurationHours
      return result < 0 ? 0 : result
    },
    getFullExtensionInMinutes () {
      if (!this.currentShift) {
        return 0
      }
      const currentDurationInHours = this.getCurrentRelayFullDurationInHours
      if (currentDurationInHours < 0 || currentDurationInHours >= this.getRelayMaxDuration) {
        return 0
      }
      const maxExtensionInMinutes = this.getMaxAllowedExtensionDurationInHours * 60
      let maxIntervalToNextShiftInMinutes = null
      const self = this
      if (this.hasNextShift) {
        maxIntervalToNextShiftInMinutes = this.getMaxIntervalToNextShiftInMinutes
        self.extensionConflictWithNextShift = maxIntervalToNextShiftInMinutes < maxExtensionInMinutes
        return Math.min(maxExtensionInMinutes, maxIntervalToNextShiftInMinutes)
      }
      self.extensionConflictWithNextShift = false
      const result = this.getMaxAllowedExtensionDurationInHours * 60
      return result < 0 ? 0 : result
    },
    getMaxAllowedExtensionDurationInHours () {
      if (!this.currentShift) {
        return 0
      }
      // кол-во часов для продления
      const currentDurationInHours = this.getCurrentRelayFullDurationInHours

      // break
      if (currentDurationInHours >= this.getRelayMaxDuration) {
        return 0
      }

      const result = this.getRelayMaxDuration - currentDurationInHours
      return result < 0 ? 0 : result
    },
    getExtensionDurationHoursValue () {
      // целое кол-во часов для продления
      const result = Math.floor(this.getFullExtensionInMinutes / 60)
      return result < 0 ? 0 : result
    },
    getExtensionDurationMinutesValue () {
      // кол-во минут для продления
      const result = parseInt(`${this.getFullExtensionInMinutes % 60}`, 10)
      return result < 0 ? 0 : result
    },
    getNextShiftDiff () {
      if (!this.currentShift.nextRelayAt) {
        return null
      }
      const result = parseInt(
        `${this.$moment(this.currentShift.nextRelayAt).diff(this.currentShift.endAt, 'minutes')}`,
        10
      )
      return result < 0 ? 0 : result
    },
    getMaxIntervalToNextShiftInMinutes () {
      if (!this.hasNextShift) {
        return null
      }
      const diff = this.getNextShiftDiff - 60
      return diff < 0 ? 0 : diff
    },
    hasNextShift () {
      return !!this.currentShift.nextRelayAt
    },
    getMaxIntervalToNextShiftInHours () {
      if (!this.hasNextShift) {
        return null
      }
      const result = (this.getMaxIntervalToNextShiftInMinutes / 60).toFixed(1)
      return result < 0 ? 0 : result
    },
    getNextShiftStartRemainHours () {
      if (!this.hasNextShift) {
        return null
      }
      const result = Math.floor((this.getNextShiftDiff / 60))
      return result < 0 ? 0 : result
    },
    getNextShiftStartRemainMinutes () {
      if (!this.hasNextShift) {
        return null
      }
      const result = this.getNextShiftDiff % 60
      return result < 0 ? 0 : result
    },
    relayExtendable () {
      const max = this.getFullExtensionInMinutes
      return (parseInt(this.hours) * 60) + parseInt(this.minutes) < max
    },
    canChangeMinutes () {
      return this.relayExtendable
    },
    refillAmountRequired () {
      if (!this.currentOffice || !this.currentOffice.amount || parseFloat(this.currentOffice.amount) < 1) {
        return false
      }
      return parseFloat(this.getExtendPay) >= parseFloat(this.currentOffice.amount)
    },
    getRequiredAmount () {
      if (!this.currentOffice || !this.currentOffice.amount || parseFloat(this.currentOffice.amount) < 1) {
        return false
      }
      return parseFloat((parseFloat(this.getExtendPay) - parseFloat(this.currentOffice.amount)).toFixed(2))
    },
    getExtendPay () {
      const costPerHour = parseFloat(this.currentShift.offer.costPerHour)
      const isSelfEmployment = this.currentShift.isSelfEmployment
      const duration = parseInt(this.hours)
      const tax = isSelfEmployment ? 0 : (this.calculateTax(costPerHour))
      const unit = parseFloat((costPerHour + tax + 1).toFixed(2))
      const additionalHours = parseFloat((this.minutes / 60).toFixed(2))
      const expense = unit * (duration + additionalHours)

      return expense ? expense.toFixed(2) : 0
    },
    shiftFullFormattedStartDate () {
      return `${this.currentShift.startDate} ${this.currentShift.startTime}`
    },
    startModalStartDate () {
      const date = localFormattedDate()
      const day = this.prependZero(date.day)
      return `${day} ${date.month} ${this.prependZero(date.hour)}:${this.prependZero(date.minutes)}`
    },
    isArchived () {
      return ![
        this.statusList.STATUS_ACCEPTED,
        this.statusList.STATUS_ACTIVE,
        this.statusList.STATUS_CREATED,
      ].includes(this.currentShift.status)
    },
    getDelayedStartDate () {
      if (!this.currentShift) {
        return ''
      }
      const d = new Date(this.currentShift.startAt)
      const delayedInterval = this.currentShift.offer.delayed ? 0 : 30
      const delayed = new Date(d.getTime() + delayedInterval * 60 * 1000)
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
      const localeDateSplited = delayed.toLocaleDateString('ru-RU', options).split(' ')
      const delayedDate = `${localeDateSplited[1]} ${localeDateSplited[2]} в `
      const delayedTime = `${delayed.getHours()}:${this.prependZero(delayed.getMinutes())}`
      return { delayedTime, delayedDate }
    },
    getRatingIconList () {
      const currentList = this.ratingReasonList.filter(
        (rateGroup) => parseInt(rateGroup.id) === parseInt(this.rate.value)
      )
      if (currentList.length) {
        return currentList[0].reasonList
      }

      return []
    },
    currentOffice () {
      return this.$store.state.office.userOffice
    },
    hasIncident () {
      return !!this.currentShift.incident && !!this.currentShift.incident.id
    },
    hasAbsence () {
      return !!this.currentShift.laborerAbsence && !!this.currentShift.laborerAbsence.id
    },
    hasRejectRequest () {
      return this.currentShift && !!this.currentShift.rejectRequest && !!this.currentShift.rejectRequest.id
    },
    currentShift () {
      return {
        ...this.$store.state.shift.current,
      }
    },
    laborerRejectReasonList () {
      return this.$store.state.shift.laborerRejectReasonList
    },
    ratingReasonList () {
      return this.$store.state.shift.ratingReasonList
    },
    incidentTypeList () {
      return this.isArchived
        ? this.$store.state.shift.incidentTypeList.filter((item) => item.alias !== this.earlyWorkFinishedAlias)
        : this.$store.state.shift.incidentTypeList
    }
  },
  watch: {
    'toStopMinutes': function (val, oldVal) {
      // const next = parseInt(val)
      // if (parseInt(oldVal) !== next) {
      // //   if (parseInt(val) > this.getToStopMaxMinutes) {
      // //     this.toStopMinutes = this.prependZero(this.getToStopMaxMinutes)
      // //   }
      //   if (next < this.getToStopMinMinutes) {
      //     this.$set(this, `toStopMinutes`, this.prependZero(this.getToStopMinMinutes))
      //   } else {
      //     // this.toStopMinutes = this.prependZero(this.getToStopMaxMinutes)
      //   }
      // }
    },
    'minutes': function (val, oldVal) {
      const max = this.getFullExtensionInMinutes
      const minutes = parseInt(val)
      if (parseInt(oldVal) !== parseInt(val) && val && val > 0) {
        if (minutes + parseInt(this.hours) * 60 > max) {
          this.$set(this, `minutes`, this.prependZero(this.getExtensionDurationMinutesValue))
          this.$set(this, `hours`, this.prependZero(this.getExtensionDurationHoursValue))
        } else {
          this.minutes = minutes > 59 ? 59 : this.prependZero(minutes)
        }
      }
    },
    'hours': function (val, oldVal) {
      const maxHours = this.getExtensionDurationHoursValue
      const hours = parseInt(val)
      if (parseInt(oldVal) !== parseInt(val) && val && val > 0) {
        if (hours > maxHours) {
          this.$set(this, `minutes`, this.prependZero(this.getExtensionDurationMinutesValue))
          this.$set(this, `hours`, this.prependZero(this.getExtensionDurationHoursValue))
        } else {
          this.hours = this.prependZero(hours)
        }
      }
    },
    'rate.value': function (val) {
      this.selectedRateReason = []
    },
    'earlyStopData.incidentType': function (val) {
      this.compensationConfirmed = false
    },
  },
  destroyed () {
    this.$store.commit('offer/offerLaborerList', [])
    this.$store.commit('offer/setCurrentOfferLaborer', null)
  },
  beforeUpdate () {
    this.updateChatSize('shift-header-container')
  },
  updated () {
    this.updateChatSize('shift-header-container')
  },
  mounted () {
    this.$nextTick(function () {
      this.updateChatSize('shift-header-container')
    })
  },
  created () {
    this.currentTimerInterval = setInterval(() => {
      this.formatDate()
    }, 1000)
    this.getShift(this.$route.params.shiftId)
    this.fetchLaborerRejectReasonList()
    this.fetchRatingReasonList()
    this.fetchIncidentTypeList()

    this.eventsBus.$on(
      this.eventNames.ON_OFFER_INVITATION_REJECTED_NOTIFICATION, (data) => {
        if (this.currentShift &&
          (
            this.currentShift.id === data.resourceId ||
            this.currentShift.offer.parent === data.parentId
          )
        ) {
          const { extra: { resource: { status } } } = data
          if (status && status === 'accepted') {
            this.$router.push({
              name: routeList.OFFICE_OFFER_ID_NAME,
              params: { id: this.$route.params.id, offerId: data.parentId }
            })
          }
        }
      }
    )
    this.eventsBus.$on(
      this.eventNames.ALIAS_ON_LABORER_TELEMETRY_START_NOTIFICATION, (data) => {
        if (this.currentShift && (this.currentShift.id === data.resourceId)) {
          setTimeout(() => {
            this.getShift(data.resourceId)
          }, 1000)
        }
      }
    )

    this.eventsBus.$on(
      this.eventNames.ON_LABORER_REACHED_OFFICE_GEO_ZONE_NOTIFICATION, (data) => {
        if (this.currentShift && data.extra.invitation === this.currentShift.id) {
          // this.addHashToLocation(data)
          setTimeout(() => {
            this.getShift(this.currentShift.id)
          }, 1000)
          this.currentModal = this.modalList.CAN_START_SHIFT_MODAL
        }
      }
    )
    this.eventsBus.$on(
      this.eventNames.ON_RELAY_END_CONFIRMED_NOTIFICATION, (data) => {
        if (this.currentShift && this.currentShift.id === data.resourceId) {
          clearInterval(this.currentTimerInterval)
          this.getShift(data.resourceId)
          this.isRequested = false
          this.currentModal = this.modalList.SHIFT_RATE_MODAL
        }
      }
    )
    this.eventsBus.$on(
      this.eventNames.ALIAS_ON_RELAY_REJECTED_NOTIFICATION, (data) => {
        if (this.currentShift && this.currentShift.id === data.resourceId) {
          clearInterval(this.currentTimerInterval)
          this.getShift(data.resourceId)
          this.isRequested = false
        }
      }
    )
    this.eventsBus.$on(
      this.eventNames.ON_RELAY_SYSTEM_CANCEL_NOTIFICATION, (data) => {
        if (this.currentShift && this.currentShift.id === data.resourceId) {
          clearInterval(this.currentTimerInterval)
          this.getShift(data.resourceId)
          this.isRequested = false
        }
      }
    )
    this.eventsBus.$on(
      this.eventNames.ON_RELAY_EXTEND_REQUEST_CONFIRMED_NOTIFICATION, (data) => {
        if (this.currentShift && this.currentShift.id === data.resourceId) {
          this.getShift(data.resourceId)
        }
      }
    )
    this.eventsBus.$on(
      this.eventNames.ON_RELAY_START_NOTIFICATION, (data) => {
        if (this.currentShift && this.currentShift.id === data.resourceId) {
          this.getShift(data.resourceId)
        }
      }
    )
    this.eventsBus.$on(
      this.eventNames.ON_RELAY_EXTEND_REQUEST_CANCELLED_NOTIFICATION, (data) => {
        if (this.currentShift && this.currentShift.id === data.resourceId) {
          this.getShift(data.resourceId)
        }
      }
    )
    this.eventsBus.$on(
      this.eventNames.ALIAS_ON_RELAY_REJECT_REQUEST_CANCELED_NOTIFICATION, (data) => {
        if (this.currentShift && this.currentShift.id === data.resourceId) {
          this.getShift(data.resourceId)
        }
      }
    )
    this.eventsBus.$on(
      this.eventNames.ALIAS_ON_LABORER_ABSENCE_REJECTED_NOTIFICATION, (data) => {
        if (this.currentShift && this.currentShift.id === data.resourceId) {
          this.getShift(data.resourceId)
        }
      }
    )
    this.eventsBus.$on(
      this.eventNames.ON_OFFER_WORKFLOW_SYSTEM_CANCEL_NOTIFICATION, (data) => {
        if (this.currentShift && this.currentShift.offer && data.resourceId === this.currentShift.offer.id) {
          this.$router.push({ name: routeList.OFFICE_SHIFT_LIST_NAME, params: { id: this.currentOffice.id } })
        }
      }
    )
    this.eventsBus.$on(
      this.eventNames.ON_RELAY_REJECT_REQUEST_NOTIFICATION, (data) => {
        if (this.currentShift && this.currentShift.id === data.resourceId) {
          this.$store.dispatch(
            'shift/fetchShiftByID',
            { id: data.resourceId }).then((response) => {
            this.currentModal = this.modalList.REJECT_SHIFT_REQUEST_MODAL
          })
          this.isRequested = false
        }
      }
    )
    // this.currentModal = this.modalList.REJECT_SHIFT_REQUEST_MODAL
  },
  beforeDestroy () {
    this.$store.commit('shift/setShiftCurrent', null)
    this.$store.commit(
      'chat/clearMessage', {
      }
    )

    clearInterval(this.currentTimerInterval)
  },
  methods: {
    onStopShiftCancel () {
      this.compensationConfirmed = !this.compensationConfirmed
      this.currentModal = ''
      this.earlyStopData = {
        incidentType: null,
        description: ''
      }
    },
    addHashToLocation (data) {
      const host = `${window.location.protocol}//${window.location.host}`
      const base = `${host}/office/b82859a9-b694-48d3-b2b4-f94b005d0d36/shift/`
      history.pushState(
        {},
        null,
        `${base}${data.resourceId}?status=relay`
      )
    },
    formatDate () {
      if (!this.currentShift || !this.currentShift.status) {
        return
      }
      if (this.currentShift.status === this.statusList.STATUS_ACCEPTED) {
        const start = this.$moment(this.currentShift.startAt)
        const now = this.$moment()
        const minutes = start.diff(now, 'minutes')
        this.showCreateButton = minutes <= 90
      }

      if (this.currentShift && this.currentShift.id && this.currentShift.id.length) {
        this.currentTimer = this.formatTimerDate(this.currentShift, this.hasAbsence, this.hasRejectRequest)
      } else {
        this.currentTimer = null
      }
    },
    hover (value) {
      this.rate.value = value
    },
    proceedRate () {
      this.isRequested = true
      const rateReasonList = this.selectedRateReason.map((id) => ({ id }))

      this.$store.dispatch('shift/rateExecutor', {
        relay: { id: this.currentShift.id },
        rateReasonList: rateReasonList,
        rate: this.rate.value,
        isFavorite: this.rate.isFavorite,
      })
        .then(() => {
          this.isRequested = false
          this.selectedRateReason = []
          this.currentModal = this.modalList.SHIFT_RATE_CONFIRMATION_MODAL
          this.getShift(this.currentShift.id, this.statusList.STATUS_CREATED)
        })
    },
    onRateValueChange ($event, value) {
    },
    onRateReasonClick (rateReason) {
      rateReason.active = rateReason.active !== true
      if (this.selectedRateReason.includes(rateReason.id)) {
        this.selectedRateReason = this.selectedRateReason.filter((reasonId) => reasonId !== rateReason.id)
      } else {
        this.selectedRateReason.push(rateReason.id)
      }
    },
    openModal (modal) {
      this.isRequested = false
      this.compensationConfirmed = false
      this.toStopMinutes = '05'
      this.minutes = '00'
      this.hours = '00'
      this.currentModal = modal
      this.selectedRateReason = []
      this.rate = {
        value: 1,
        isFavorite: this.currentShift.favoriteLaborer,
        reason: {
          id: null
        },
      }
      switch (modal) {
      case this.modalList.STOP_SHIFT_MODAL:
        this.openStopModal()
        break
      case this.modalList.START_CONFIRM_MODAL:
        this.confirmationModal.title = ''
        break
      }
    },
    openStopModal () {
      if (this.isArchived && !this.earlyStopData.id && this.currentShift.incident && this.currentShift.incident.id) {
        this.earlyStopData = {
          id: this.currentShift.incident.id,
          incidentType: this.currentShift.incident.type,
          description: this.currentShift.incident.description
        }
      }
    },
    selectLaborer (laborer) {
      this.selectedUser = laborer
    },
    closeModal () {
      this.currentModal = ''
    },
    async proceedDelay (delayEnd) {
      try {
        this.isRequested = true

        await this.$store.dispatch('shift/delay', {
          payload: {
            offerInvitation: { id: this.currentShift.id },
            changeDuration: delayEnd === true
          }
        })
        this.isRequested = false
        this.currentModal = this.modalList.DELAY_MODAL_CONFIRM_MODAL
        this.getShift()
      } catch (error) {
        console.log(error)
        this.isRequested = true
      }
    },
    onCancel () {
      if ([this.statusList.STATUS_CREATED, this.statusList.STATUS_ACTIVE].includes(this.currentShift.status)) {
        this.cancelShift()
      } else {
        this.cancelFutureShift()
      }
    },
    cancelShift (showNextModal = true) {
      this.isRequested = true
      try {
        this.$store.dispatch('shift/fetchShiftCancel', { id: this.currentShift.id })
          .then(() => {
            this.isRequested = false
            if (showNextModal) {
              this.currentModal = this.modalList.SHIFT_CANCELLED_MODAL
            }
            this.getShift(this.currentShift.id, this.statusList.STATUS_CREATED)
          }).catch(() => {
            this.isRequested = false
          })
      } catch (error) {
        console.log(error)
        this.isRequested = false
      }
    },
    cancelFutureShift () {
      try {
        this.isRequested = true
        this.$store.dispatch('offer/fetchOfferInvitationCancel', { offerInvitationId: this.currentShift.id })
          .then(() => {
            this.isRequested = false
            this.currentModal = this.modalList.SHIFT_CANCELLED_MODAL
            this.$router.push({ name: routeList.OFFICE_SHIFT_LIST_NAME, params: { id: this.currentOffice.id } })
          }).catch(() => {
            this.isRequested = false
          })
      } catch (error) {
        this.isRequested = false
        console.log(error)
      }
    },
    onConfirmStart () {
      this.isRequested = true
      this.$store.dispatch('shift/confirmStart', {
        id: this.currentShift.id,
      })
        .then(() => {
          this.isRequested = false
          this.confirmationModal.title = 'Начало смены Успешно подтверждено!'
          this.currentModal = this.modalList.START_CONFIRM_MODAL
          this.getShift(this.currentShift.id, this.statusList.STATUS_CREATED)
        })
    },
    laborerMissing () {
      this.isRequested = true
      this.$store.dispatch('shift/laborerMissing', {
        relay: { id: this.currentShift.id },
      })
        .then(() => {
          this.isRequested = false
          this.confirmationModal.title = 'Завершение смены успешно запланировано!'
          this.currentModal = this.modalList.START_CONFIRM_MODAL
          this.getShift(this.currentShift.id, this.statusList.STATUS_CREATED)
        })
    },
    laborerMissingCancel () {
      this.isRequested = true
      this.$store.dispatch('shift/laborerMissingCancel', {
        relay: { id: this.currentShift.id },
      })
        .then(() => {
          this.isRequested = false
          this.confirmationModal.title = 'Смена успешно восстановлена!'
          this.currentModal = this.modalList.START_CONFIRM_MODAL
          this.getShift(this.currentShift.id, this.statusList.STATUS_CREATED)
        })
    },
    onStopShiftOrCreateIncident () {
      if (this.isArchived) {
        this.createIncident()
      } else {
        this.onEarlyStop()
      }
    },
    replyRejectRequest (rejectConfirmed = false) {
      this.isRequested = true
      this.$store.dispatch('shift/replyRejectRequest', {
        relay: { id: this.currentShift.id },
        rejectConfirmed,
      })
        .then(() => {
          this.getShift(this.currentShift.id, this.statusList.STATUS_CREATED)
          this.closeModal()
        })
    },
    cancelRejectRequest () {
      if (this.hasRejectRequest) {
        this.isRequested = true
        this.$store.dispatch('shift/cancelRejectRequest', {
          id: this.currentShift.rejectRequest.id
        })
          .then(() => {
            this.getShift(this.currentShift.id, this.statusList.STATUS_CREATED)
            this.closeModal()
          })
      }
    },
    onEarlyStop () {
      this.isRequested = true
      this.$store.dispatch('shift/stop', {
        relay: { id: this.currentShift.id },
        incidentType: { id: this.earlyStopData.incidentType.id },
        description: this.earlyStopData.description,
        endDate: this.$moment().add((parseInt(this.toStopMinutes)), 'minutes').utc()
      })
        .then(() => {
          this.isRequested = false
          this.compensationConfirmed = false
          if (this.earlyStopData.incidentType.alias !== this.earlyWorkFinishedAlias) {
            this.currentModal = this.modalList.SHIFT_CANCELLED_MODAL
          } else {
            this.closeModal()
          }
          this.getShift(this.currentShift.id, this.statusList.STATUS_CREATED)
        })
    },
    createIncident () {
      this.isRequested = true
      this.$store.dispatch('shift/createIncident', {
        relay: { id: this.currentShift.id },
        type: { id: this.earlyStopData.incidentType.id },
        description: this.earlyStopData.description
      })
        .then(() => {
          this.isRequested = false
          this.currentModal = this.modalList.SHIFT_CANCELLED_MODAL
          this.getShift(this.currentShift.id, this.statusList.STATUS_CREATED)
        })
    },
    getShift (id) {
      const { status, type } = this.$route.query
      id = id || this.currentShift.id
      const isInvitation = status === this.statusList.STATUS_REJECTED && type === 'invitation'

      this.$store.dispatch(
        'shift/fetchShiftByID',
        { id, isInvitation })
    },
    startShift (isAcceptedInvitation) {
      this.isRequested = true
      try {
        this.$store.dispatch('shift/launchShift', { id: this.currentShift.id, isAcceptedInvitation })
          .then(() => {
            this.isRequested = false
            this.getShift(this.currentShift.id, this.statusList.STATUS_CREATED)
            this.currentModal = ''
          }).catch(() => {
            this.isRequested = false
          })
      } catch (error) {
        console.log(error)
        this.isRequested = false
      }
    },
    fetchLaborerRejectReasonList () {
      this.$store.dispatch('shift/fetchLaborerRejectReasonList')
    },
    fetchRatingReasonList () {
      this.$store.dispatch('shift/fetchRatingReasonList')
    },
    fetchIncidentTypeList () {
      this.$store.dispatch('shift/fetchIncidentTypeList')
    },
    rejectLaborer () {
      this.isRequested = true
      this.$store.dispatch('shift/rejectLaborer', {
        relay: { id: this.currentShift.id },
        laborerRejectReason: { id: this.laborerRejectData.incidentType.id },
        description: this.laborerRejectData.description
      })
        .then(() => {
          this.isRequested = false
          this.currentModal = this.modalList.SHIFT_CANCELLED_MODAL
          this.getShift(this.currentShift.id, this.statusList.STATUS_CREATED)
        })
    },
    closeOnStart () {
      this.currentModal = ''
    },
    onRejectLaborer () {
      this.currentModal = this.modalList.REJECT_LABORER_MODAL
    },
    goToRefillAmount () {
      this.currentModal = null
      this.$store.dispatch('popup/showModal', { key: 'showOfferBuyForm' })
    },
    proceedExtend () {
      this.isRequested = true

      if (parseInt(this.hours, 10) === 0 && parseInt(this.minutes, 10) === 0) {
        return
      }

      this.$store.dispatch('shift/extend', {
        relay: { id: this.currentShift.id },
        extendedCostPerHour: this.currentShift.offer.costPerHour,
        time: `${this.hours}:${this.minutes}:00`,
      })
        .then(() => {
          this.isRequested = false
          this.currentModal = this.modalList.SHIFT_EXTEND_CONFIRM_MODAL
          this.getShift(this.currentShift.id, this.statusList.STATUS_CREATED)
        })
    },
    onToStopMinutes ($event) {
      const next = parseInt($event)
      if (isNaN(next) || !next || next < this.getToStopMinMinutes) {
        this.$set(this, `toStopMinutes`, this.prependZero(this.getToStopMinMinutes))
      } else {
        this.$set(this, `toStopMinutes`, this.prependZero(next))
      }
    },
    onMinutesUpdate (val) {},
    onHoursChange (val) {
      if (!val || isNaN(val)) {
        this.hours = '00'
      }
    },
    onMinutesChange (val) {
      if (!val || isNaN(val)) {
        this.minutes = '00'
      }
    },
    _pluriaizeMinutes (val) {
      return pluriaizeMinutes(val)
    },
    _pluriaizeHours (val) {
      return pluriaizeHours(val)
    },
    keyUp ($event, name) {
      switch (name) {
      case 'extendMinutes':
        if ($event.keyCode === 40 && this.minutes > 0) {
          this.minutes--
        } else if ($event.keyCode === 38 && this.minutes < 60) {
          this.minutes++
        }
        break
      case 'extendHours':
        if ($event.keyCode === 40 && this.hours > 0) {
          this.hours--
        } else if ($event.keyCode === 38 && this.minutes < this.getExtensionDurationHoursValue) {
          this.hours++
        }
        break
      case 'toStopMinutes':
        break
      }
    },
  },
}
</script>
.
<style lang="sass" scoped>

.trustability
  @include fontStyle(500, 10px)
  color: rgba(0, 0, 0, 0.5)

.rate-reason-container
  justify-content: center
  display: flex
  align-self: center
  align-items: center
  margin-top: 10px
  text-align: center
.rate-reason-container-item
  max-width: 110px
  min-height: 130px
  margin: 0
  padding: 0
  display: inline-block
  vertical-align: top
.reject-description
  color: rgba(0,0,0,0.6)
  margin-top: 0
.reject-timer-label
  font-style: normal
  font-weight: 800
  font-size: 26px
  line-height: 32px
  text-align: center
  color: #000000
  margin: 0
.chat-box
  padding: 8px
  border-radius: 4px
.chat-author
  font-style: normal
  font-weight: 800
  font-size: 12px
  line-height: 15px
  color: #000000
.chat-time
  font-style: normal
  font-weight: 500
  font-size: 12px
  line-height: 15px
  color: #000000
  opacity: 0.3
.reject-request-line
  display: flex
  justify-content: flex-start
  align-items: center
  padding: 8px 0
  &__bordered
    border: $default_border_bottom
.line-divider
  margin-bottom: 38px
  margin-top: 38px
  &__top
    border-top: $default_border_bottom
  &__bottom
    border-bottom: $default_border_bottom
  .offer-item
    &__left
      display: flex
      flex-direction: column
      width: 45%
      margin-right: 30px

      &__header__status
        display: flex
        margin: 15px 0

        &:first-of-type
          margin-top: 0

      &__list-user
        overflow: auto
        flex-grow: 1
        height: 1px
        align-items: flex-start

        &_empty
          @include fontStyle(500, 16px)
          margin: 40px auto 0 auto
          color: rgba(0, 0, 0, 0.51)

    &__right
      width: 100%
      background-color: red
      height: 100%
      border: 1px solid

    .star
      justify-content: center
      display: flex
      margin-top: 10px

</style>
