<template>
  <svg :width="width" :height="height" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.22932 0H9.77068V2.37863L11.7635 0.43927L12.8534 1.49993L9.7707 4.49993L9.77068 7.70102L12.6193 6.10048L13.7476 2.00241L15.2365 2.39064L14.5071 5.03985L16.6238 3.85054L17.3945 5.14957L15.2777 6.33889L18 7.04875L17.6011 8.49763L13.39 7.39956L10.5414 9.00006L13.39 10.6006L17.601 9.50248L18 10.9514L15.2777 11.6612L17.3945 12.8505L16.6238 14.1496L14.507 12.9603L15.2365 15.6095L13.7476 15.9977L12.6193 11.8996L9.77068 10.2991V13.5001L12.8534 16.5001L11.7635 17.5607L9.77068 15.6214V18H8.22932V15.6214L6.23649 17.5607L5.14658 16.5001L8.2293 13.5001L8.22932 10.2991L5.38073 11.8996L4.25238 15.9977L2.76354 15.6095L3.49297 12.9603L1.37621 14.1496L0.605531 12.8505L2.72229 11.6612L2.15685e-05 10.9514L0.398955 9.50248L4.61003 10.6006L7.45864 9.00006L4.61 7.39953L0.398933 8.49763L0 7.04874L2.72226 6.33889L0.60551 5.14957L1.37619 3.85054L3.49294 5.03985L2.76352 2.39063L4.25235 2.00241L5.38071 6.10048L8.22932 7.70102V4.49991L5.14658 1.49993L6.23648 0.43927L8.22932 2.37863V0Z"
      :fill="active ? activeColor : fill || '#7A7A7A'"
    />
  </svg>
</template>

<script>
  import icon_config from "@/mixins/icon_config";

  export default {
    name: "IconColdMark",
    mixins: [icon_config],
  }
</script>

<style scoped>

</style>
