<template>
  <svg :width="width" :height="height" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.5883 13.6039L1.9763 0H1.40311L1.28607 0.297218C0.97717 1.08172 0.845185 1.88409 0.904439 2.6175C0.971979 3.4535 1.28976 4.17728 1.82349 4.71068L7.54909 10.4329L8.3043 9.67811L13.5976 15.5935C14.1242 16.1198 15.0305 16.151 15.5883 15.5935C16.1372 15.0449 16.1372 14.1524 15.5883 13.6039Z"  :fill="active ? activeColor : fill || '#7A7A7A'"/>
    <path d="M4.89589 9.10754L0.411621 13.5891C-0.137207 14.1376 -0.137207 15.0302 0.411621 15.5787C0.932995 16.0997 1.83494 16.1457 2.40234 15.5787L6.88664 11.097L4.89589 9.10754Z"  :fill="active ? activeColor : fill || '#7A7A7A'"/>
    <path d="M15.3248 2.66405L12.781 5.20623L12.1174 4.54305L14.6612 2.00083L13.9976 1.33765L11.4539 3.87983L10.7903 3.21664L13.334 0.674459L12.6705 0.0113042L9.35255 3.32724C8.94665 3.73289 8.70429 4.27152 8.67002 4.84395C8.66136 4.98898 8.62846 5.13164 8.5739 5.26733L10.7292 7.42136C10.865 7.36676 11.0078 7.33392 11.1528 7.32526C11.7256 7.29111 12.2646 7.04882 12.6705 6.64317L15.9884 3.32727L15.3248 2.66405Z" :fill="active ? activeColor : fill || '#7A7A7A'"
    />
  </svg>
</template>

<script>
    import icon_config from "@/mixins/icon_config";

    export default {
        name: "IconFreeEat",
        mixins: [icon_config],
    }
</script>

<style scoped>

</style>
