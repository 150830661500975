<template>
  <div
    class="nav-item"
  >
    <AppButton
      id="dropDown-wrapper"
      class=""
      style="margin: 0;     padding: 4px 10px !important"
      @click.native="onChoice"
    >
      {{ label }}
    </AppButton>
    <ul
      v-if="isOpen"
      class="nav-item__children"
    >
      <template
        v-for="item in menuItems"
      >
        <li
          :key="item.id"
          class="nav-item__children__el"
          @click="close($event, item)"
        >
          {{ item.label }}
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import AppButton from '@/components/common/simple/AppButton'

export default {
  name: 'WbdDropdown',
  components: {
    AppButton,
  },
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      isOpen: false,
    }
  },
  watch: {
    isOpen (val) {
      if (val) {
        document.addEventListener('click', this.close)
      } else {
        document.removeEventListener('click', this.close)
      }
    }
  },
  methods: {
    onChoice () {
      this.isOpen = !this.isOpen
    },
    close ($event, item) {
      if ($event.target && $event.target.id === 'dropDown-wrapper') {
        return
      }
      if (item) {
        this.$emit('onDropdownSelected', item)
      }
      this.isOpen = false
    }
  },
}
</script>

<style lang="sass" scoped>
  .nav-item
    position: relative
    &__title
      @include fontStyle(800, 16px)
      color: rgba(0, 0, 0, 0.3)
      &_active
        color: rgb(0, 0, 0)
    &__drop-icon
      margin-left: 4px
      transition: all .5s
    &__children
      position: absolute
      z-index: 1
      min-width: 100%
      top: 100%
      right: 0
      //background: #F0F0F1
      background: #FFFFFF
      border: 1.5px solid rgba(0, 0, 0, 0.1)
      box-shadow: 0 10px 15px rgba(117, 117, 117, 0.1)
      border-radius: 6px
      padding: 10px 10px
      &__el
        @extend %navigation__children-item
        color: rgba(0, 0, 0, 0.51)
        padding: 5px
        margin-bottom: 3px
        cursor: pointer
        color: #0c0c0c
        &:last-child
          margin-bottom: 0
        &:hover
          color: #8BD118
        &_active
          @extend %navigation__children-item
          color: rgb(0, 0, 0)

  .isOpen
    transform: rotate(180deg)
</style>
